import React from 'react';
import './Modal.css';

const ModalInfo = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-info-overlay" onClick={onClose}>
      <div className="modal-info-content max-w-[500px]" onClick={(e) => e.stopPropagation()}>
        <button className="modal-info-close-button" onClick={onClose}>
          &times;
        </button>
        <div className={`flex flex-col gap-2 justify-center mx-auto bg-white-a700 bg-opacity-80 rounded-2xl w-full h-fit p-2`}>
          <div className="flex-1">
            <p className="text-left mb-2">¡Hola! Esta es una demo de un agente de inteligencia artificial que empuja ventas de artículos de cocina en la pagina web de un distribuidor.</p>
            <p className="text-left mb-2">Favor asume que estás en la siguiente situación: Ud está navegando la pagina web porque de alguna forma está interesado en el producto pero no ha tomado decisión. Favor probar si el agente <b>le ayuda a entusiasmarse para tomar la decisión de compra.</b></p>
            <b className="h-1">Algunas sugerencias:</b>
            <ul className="list-disc pl-5">
              <li>puede pedirle que le explique precios y condiciones de financiamiento.</li>
              <li>puede pedirle que le muestre fotos y videos.</li>
              <li>puede aclarar muchas dudas del producto, comparativas de marcas, precios, etc.</li>
            </ul>
          </div>
          <div className="flex-1">
            <b className="h-1">Restricciones:</b>
            <ul className="list-disc pl-5">
              <li>tiene un limite de 20 interacciones durante la demo.</li>
              <li>la base de conocimiento del demo es de 8 artículos de cocina.</li>
            </ul>
            <p className="text-left mt-2"><b className="h-1">Nota para interesados:</b> Estos agentes de IA son personalizables para todos las industrias, servicios y productos, y funcionan con la base de conocimiento que el cliente empresa define. Fácil de integrar con los sistemas de las empresas.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalInfo };